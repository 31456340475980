<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('nav.billing')">
      <div
        :class="[
          $style.base,
          {
            [$style.smallScreen]: $screen === 's' || $screen === 'm'
          }
        ]"
        v-test="'admin-billing-page'"
      >
        <AlreadySubscribedAlert />
        <CancelledSubscriptionRemainingTimeCard />
        <PausedSubscriptionRemainingTimeCard />
        <BlockedPage v-if="isBlocked" />
        <div
          v-else-if="isSubscribed"
          :class="[$style.contentArea, $style.cardGrid]"
        >
          <div :class="$style.left">
            <VatBanner />
            <PlanCard />
            <UpgradeCard />
          </div>
          <div :class="$style.right">
            <StripeInvoicesCard v-if="!isCollectivePayment" />
            <SMSCard :showPaymentAlert="isSmsPaymentSuccess" />
            <ReferralCard :noImage="true" />
            <Dac7PDFs mb />
          </div>
        </div>
        <div v-else :class="$style.contentArea">
          <RemainingTrialCard />
          <SubscribeCard />
          <DemoCard />
          <RattingsCard />
        </div>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import VatBanner from './VatBanner.vue';
import PlanCard from './PlanCard.vue';
import UpgradeCard from './UpgradeCard.vue';
import AlreadySubscribedAlert from './AlreadySubscribedAlert.vue';
import SubscribeCard from './SubscribeCard.vue';
import BlockedPage from './BlockedPage.vue';
import DemoCard from './DemoCard.vue';
import RattingsCard from './RattingsCard.vue';
import CancelledSubscriptionRemainingTimeCard from './CancelledSubscriptionRemainingTimeCard.vue';
import PausedSubscriptionRemainingTimeCard from './PausedSubscriptionRemainingTimeCard.vue';
import SMSCard from '../_shared/SMSCard.vue';
import { useCompanyStore } from '@/stores/company';
import ReferralCard from '@/modules/_shared/ReferralCard.vue';
import RemainingTrialCard from './RemainingTrialCard.vue';
import StripeInvoicesCard from './stripe-invoices-card/index.vue';
import PageWrap from '../PageWrap.vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import Dac7PDFs from './Dac7PDFs.vue';

const { isSubscribed, isBlocked, isCollectivePayment } = useCompanyStore();

const isSmsPaymentSuccess = ref(false);

const route = useRoute();
const router = useRouter();

if (route.query.payment === 'success') {
  isSmsPaymentSuccess.value = true;
  router.replace({
    name: 'admin-billing'
  });
}
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
}

.contentArea {
  flex-grow: 1;
}

.cardGrid {
  flex-grow: 1;
  display: flex;
  .base.smallScreen & {
    flex-direction: column;
  }
}

.right,
.left {
  width: 100%;
}

.left {
  .base:not(.smallScreen) & {
    width: 60%;
    padding-right: $spacing * 2;
  }
}

.right {
  .base:not(.smallScreen) & {
    width: 40%;
    min-width: 490px;
  }
}
</style>
